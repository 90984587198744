@use "sass:string";
@use "sass:color";
@use "sass:math";

$wcag-dark: #1d1d1b;
$wcag-light: #ffffff;
$kendo-color-primary: #e0cc00;

$kendo-color-secondary: #0053a6;

$kendo-color-info: #0a68c6;
$kendo-color-success: #0f9124;
$kendo-color-warning: #f2ae00;
$kendo-color-error: #c60000;
$kendo-color-dark: #123860;
$kendo-color-light: $wcag-light;
$white-ghost: rgba($wcag-light, 0.8);

$success-8-solid: color.mix($kendo-color-success, $wcag-light, 8);
$warning-8-solid: color.mix($kendo-color-warning, $wcag-light, 8);

$kendo-body-text: $wcag-dark;
$kendo-subtle-text: #666666;
$kendo-disabled-text: #8f8f8f;
$kendo-input-placeholder-text: color.mix($kendo-subtle-text, $wcag-light, 50);

$kendo-base-border: rgba(0, 0, 0, 0.08);
$kendo-component-border: $kendo-base-border;
$component-border-solid: color.mix(#000000, $wcag-light, 8);

$elevated-shadow-xs: 0px 1px 2px rgba(0, 0, 0, 0.08);
$elevated-shadow-sm: 0px 1px 4px rgba(0, 0, 0, 0.16);
$elevated-shadow-md: 0px 4px 16px rgba(0, 0, 0, 0.25);
$elevated-shadow-lg: 1px 1px 16px 2px rgba(0, 0, 0, 0.3);
$bar-shadow: 0px 4px 4px rgba(#cecece, 0.25);
$bar-shadow-reversed: 0px -4px 4px rgba(#cecece, 0.25);

$kendo-base-bg: #f5f5f5;
$kendo-hover-bg: rgba(0, 0, 0, 0.08);

$kendo-selected-bg: rgba($kendo-color-primary, 0.3);
$kendo-selected-hover-bg: rgba($kendo-color-primary, 0.5);
$kendo-selected-text: $kendo-body-text;

$kendo-link-text: $kendo-color-secondary;
$kendo-link-hover-text: color.mix(#000000, $kendo-link-text, 24);
$link-text-alt: #a3d1ff;
$link-hover-text-alt: #96c0eb;
$kendo-color-secondary-pale: #ebf4ff;

$kendo-enable-typography: true;
$kendo-font-family: 'Poppins', Verdana, Arial, sans-serif;
$kendo-font-size: 14px;
$kendo-font-size-xl: 18px;
$kendo-line-height: 1.5;
$kendo-font-weight-bold: 600;

$kendo-disabled-filter: none;

$kendo-display3-font-size: 48px;
$kendo-display3-line-height: 1;
$kendo-display3-font-weight: 500;

$kendo-display4-font-size: 36px;
$kendo-display4-line-height: $kendo-line-height;

$kendo-h1-font-size: 24px;
$kendo-h1-line-height: $kendo-line-height;
$kendo-h1-font-weight: 700;
$kendo-h1-margin: 0;

$kendo-h2-font-size: 18px;
$kendo-h2-line-height: $kendo-line-height;
$kendo-h2-font-weight: 700;
$kendo-h2-margin: 0;

$kendo-h3-font-size: 14px;
$kendo-h3-line-height: $kendo-line-height;
$kendo-h3-font-weight: 700;
$kendo-h3-margin: 0;

$kendo-h4-font-size: 12px;
$kendo-h4-line-height: $kendo-line-height;
$kendo-h4-font-weight: 700;
$kendo-h4-margin: 0;

$shadow-spread: 3px;
$shadow-base: 0px 0px 0px $shadow-spread $kendo-component-border;

$kendo-paragraph-line-height: $kendo-line-height;
$kendo-paragraph-margin: 0;

$kendo-button-text: $kendo-body-text;
$kendo-button-bg: $kendo-base-bg;
$kendo-button-border: $kendo-base-border;
$kendo-button-gradient: false;
$kendo-button-focus-shadow: $shadow-base;
$kendo-button-hover-bg: #ebebeb;
$kendo-button-active-bg: #d8d8d8;
$kendo-solid-button-gradient: $kendo-button-gradient;
$kendo-button-sm-font-size: 12px;
$kendo-button-sm-padding-y: 2px;
$kendo-button-sm-padding-x: 3px;
$kendo-button-padding-y: 4px;
$kendo-button-padding-x: 7px;
$kendo-button-lg-font-size: 16px;
$kendo-button-lg-padding-y: 7px;
$kendo-button-lg-padding-x: 11px;
$kendo-button-lg-line-height: $kendo-line-height;
$kendo-button-md-line-height: 22px;
$kendo-button-md-calc-size: 32px;
$kendo-button-md-inner-calc-size: 30px;

$kendo-solid-button-hover-shade-bg-amount: 0.5;
$kendo-solid-button-hover-shade-border-amount: $kendo-solid-button-hover-shade-bg-amount;
$kendo-solid-button-active-shade-bg-amount: 1.5;
$kendo-solid-button-active-shade-border-amount: $kendo-solid-button-active-shade-bg-amount;
$kendo-solid-button-shadow-opacity: 0.3;
$kendo-solid-button-shadow-blur: 0;
$kendo-solid-button-shadow-spread: $shadow-spread;

$kendo-solid-button-shade-function: 'solid-button-color-variations';
$kendo-primary-solid-button-hover-color: #e5d429;
$kendo-primary-solid-button-active-color: #d0be02;

$kendo-outline-button-shadow-blur: 0;
$kendo-outline-button-shadow-spread: $shadow-spread;
$kendo-outline-button-shadow-opacity: 8%;

$kendo-flat-button-active-opacity: 0.12;
$kendo-flat-button-selected-opacity: 0.12;

$kendo-link-button-shadow-opacity: 0.08;

$kendo-input-md-line-height: 22 / 14;
$kendo-input-focus-shadow: $shadow-base;

$kendo-input-sm-font-size: 12px;
$kendo-input-sm-padding-x: 4px;
$kendo-input-sm-padding-y: 2px;

$kendo-input-lg-font-size: 16px;
$kendo-input-lg-padding-x: 8px;
$kendo-input-lg-padding-y: 7px;

$kendo-form-label-margin-bottom: 8px;
$kendo-form-hint-font-style: normal;

$kendo-border-radius-lg: 8px;

$kendo-avatar-sizes: (
    sm: 16px,
    md: 40px,
    lg: 64px,
    xl: 120px
);
$kendo-avatar-font-size: 16px;

$kendo-badge-md-padding-x: 3px;
$kendo-badge-md-padding-y: 3px;

$kendo-overlay-opacity: 0.8;
$kendo-overlay-bg: $wcag-dark;

$kendo-tooltip-font-size: 12px;
$kendo-tooltip-bg: $kendo-color-dark;
$kendo-tooltip-border-radius: 2px;

$kendo-actions-padding-x: 16px;
$kendo-actions-padding-y: 16px;
$kendo-actions-button-spacing: 16px;

$kendo-card-callout-width: 14px;
$kendo-card-callout-height: 14px;

$kendo-popup-shadow: $elevated-shadow-sm;
// Remove explicit setting of font size and line height in popup since the is overriding the values in the dropdowns
$kendo-popup-font-size: string.unquote('');
$kendo-popup-line-height: string.unquote('');

$kendo-menu-popup-item-focus-shadow: none;

$kendo-popover-shadow: $elevated-shadow-md;
$kendo-popover-header-padding-y: 10px;
$kendo-popover-border-radius: 4px;

$kendo-notification-padding-x: 16px;
$kendo-notification-padding-y: 8px;
$kendo-notification-font-size: 16px;
$kendo-notification-bg: $kendo-color-dark;
$kendo-notification-text: $wcag-light;
$kendo-notification-border: $kendo-color-dark;
$kendo-notification-theme-colors: (
    'primary': $kendo-color-primary,
    'secondary': $kendo-color-secondary,
    'info': $kendo-color-info,
    'success': $kendo-color-success,
    'warning': $kendo-color-warning,
    'error': $kendo-color-error
);

$kendo-notification-theme: ();
@each $name, $color in $kendo-notification-theme-colors {
    $kendo-notification-theme: map-merge(
        (
            $name: (
                color: $wcag-dark,
                background-color: color.mix($color, $wcag-light, 30),
                border: rgba($color, 0.3)
            )
        ),
        $kendo-notification-theme
    );
}

$kendo-dialog-titlebar-bg: $wcag-light;

$kendo-window-border-radius: 8px;
$kendo-window-title-font-size: $kendo-h2-font-size;
$kendo-window-title-line-height: $kendo-line-height;
$kendo-window-titlebar-bg: $wcag-light;
$kendo-window-titlebar-padding-y: 10px;
$kendo-window-shadow: $elevated-shadow-md;
$kendo-window-focus-shadow: $elevated-shadow-lg;
$kendo-window-line-height: $kendo-line-height;

$kendo-picker-hover-border: rgba($kendo-component-border, 0.16);

$kendo-list-md-item-padding-y: 5px;
$kendo-list-md-line-height: $kendo-input-md-line-height;

$kendo-list-sm-font-size: 12px;
$kendo-list-sm-item-padding-x: 4px;
$kendo-list-sm-item-padding-y: 3px;

$kendo-list-lg-font-size: 16px;
$kendo-list-lg-line-height: $kendo-line-height;
$kendo-list-lg-item-padding-x: 8px;
$kendo-list-lg-item-padding-y: 8px;

$kendo-picker-outline-hover-bg: rgba($wcag-dark, 0.08);
$kendo-picker-outline-hover-text: $kendo-body-text;
$kendo-picker-outline-hover-border: rgba($wcag-dark, 0.5);

$kendo-menu-popup-sm-font-size: 12px;
$kendo-menu-popup-sm-item-padding-x: 4px;
$kendo-menu-popup-sm-item-padding-y: 3px;

$kendo-menu-popup-lg-font-size: 16px;
$kendo-menu-popup-lg-item-padding-x: 8px;
$kendo-menu-popup-lg-item-padding-y: 8px;

$kendo-card-deck-gap: 24px;
$kendo-card-padding-x: 16px;
$kendo-card-padding-y: 16px;
$kendo-card-avatar-spacing: string.unquote('');
$kendo-card-shadow: $elevated-shadow-sm;
$kendo-card-avatar-size: string.unquote('');
$kendo-card-title-font-size: $kendo-h2-font-size;
$kendo-card-title-line-height: $kendo-h2-line-height;
$kendo-card-title-font-weight: 500;

$kendo-checkbox-border: $kendo-picker-hover-border;
$kendo-checkbox-checked-bg: color.mix($kendo-color-primary, $wcag-light, 50);
$kendo-checkbox-indeterminate-text: $wcag-dark;
$kendo-checkbox-focus-shadow: $shadow-base;
$kendo-checkbox-label-margin-x: 8px;
$kendo-checkbox-focus-checked-shadow: 0 0 0 $shadow-spread rgba($kendo-color-primary, 0.3);

$kendo-radio-label-margin-x: 8px;
$kendo-radio-list-spacing: 32px;
$kendo-radio-invalid-text: $kendo-body-text;

$kendo-stepper-indicator-done-bg: $wcag-light;
$kendo-stepper-indicator-done-text: $kendo-color-secondary;
$kendo-stepper-indicator-done-border: $kendo-color-secondary;
$kendo-stepper-indicator-done-hover-bg: color.mix($kendo-color-secondary, $wcag-light, 8);

$kendo-stepper-indicator-current-bg: $kendo-color-secondary;
$kendo-stepper-indicator-current-text: $wcag-light;
$kendo-stepper-indicator-current-hover-bg: $kendo-link-hover-text;

$kendo-stepper-indicator-focus-size: 1px;
$kendo-stepper-indicator-focus-offset: 2px;
$kendo-stepper-progressbar-fill-bg: $kendo-color-secondary;

$kendo-stepper-progressbar-size: 1px;

$kendo-grid-border-width: 0;
$kendo-grid-cell-vertical-border-width: 0;
$kendo-grid-cell-horizontal-border-width: 1px;
$kendo-grid-alt-bg: inherit;
$kendo-grid-hover-bg: rgba($kendo-body-text, 0.04);
$kendo-grid-edit-cell-padding-y: 5px;

$kendo-switch-on-track-bg: $wcag-light;
$kendo-switch-on-track-text: $kendo-body-text;
$kendo-switch-on-track-border: $kendo-component-border;
$kendo-switch-on-track-focus-ring: 2px solid $kendo-component-border;
$kendo-switch-off-thumb-bg: $kendo-color-secondary;
$kendo-switch-on-thumb-bg: $kendo-color-secondary;
$kendo-switch-sizes: (
    sm: (
        font-size: 10px,
        track-width: 36px,
        track-height: 20px,
        thumb-width: 16px,
        thumb-height: 16px,
        thumb-offset: 1px,
        label-offset: 2px
    ),
    md: (
        font-size: 10px,
        track-width: 60px,
        track-height: 30px,
        thumb-width: 24px,
        thumb-height: 24px,
        thumb-offset: 2px,
        label-offset: 6px
    ),
    lg: (
        font-size: 10px,
        track-width: 68px,
        track-height: 34px,
        thumb-width: 28px,
        thumb-height: 28px,
        thumb-offset: 2px,
        label-offset: 8px
    )
);

$kendo-pager-border-width: 0;
$kendo-pager-bg: transparent;
// The current version of Kendo react and the kendo theme do not respect the variables below
// $kendo-pager-section-spacing: 16px;

// $pager-number-text: $kendo-body-text;
// $pager-number-hover-opacity: 0.04;
// $pager-number-focus-opacity: 0.08;
// $pager-number-selected-opacity: 0.12;

//$kendo-pager-item-border-radius: 4px;
// $kendo-pager-item-hover-bg: rgba($kendo-body-text, /*$pager-number-hover-opacity*/ 0.04);
// $kendo-pager-item-focus-shadow: inset 0 0 0 2px rgba($kendo-body-text, /*$pager-number-focus-opacity*/ 0.08);
// $pager-item-selected-bg: $kendo-body-text;

// $kendo-pager-number-border-radius: $kendo-pager-item-border-radius;

$kendo-chip-line-height: $kendo-line-height;

$kendo-chip-md-line-height: $kendo-button-md-line-height;
$kendo-chip-md-padding-y: 1px;

$kendo-chip-sm-font-size: 10px;
$kendo-chip-sm-padding-y: 2px;

$kendo-chip-lg-font-size: 16px;
$kendo-chip-lg-padding-y: 4px;

$kendo-chip-solid-bg: rgba($kendo-body-text, 0.04);
$kendo-chip-solid-border: $kendo-base-border;
$kendo-chip-solid-focus-bg: $kendo-chip-solid-bg;
$kendo-chip-solid-hover-bg: rgba($kendo-body-text, 0.08);
$kendo-chip-solid-selected-bg: rgba($kendo-body-text, 0.16);
$kendo-chip-solid-shadow: 0px 0px 0px 2px $kendo-component-border;

$kendo-calendar-today-color: $kendo-color-secondary;
$kendo-time-list-title-text: $kendo-input-placeholder-text;
$kendo-calendar-caption-font-size: 12px;
$kendo-calendar-caption-font-weight: 400;

$kendo-scheduler-event-border-radius: 2px;
$kendo-scheduler-event-bg: $wcag-light;
$kendo-scheduler-event-border: $kendo-component-border;
$kendo-scheduler-event-min-height: 26px;
$kendo-scheduler-event-line-height: 18px;
$kendo-scheduler-event-selected-bg: $wcag-light;
$kendo-scheduler-event-selected-shadow: $elevated-shadow-md;

$kendo-table-selected-bg: none;
$kendo-table-md-cell-padding-x: 8px;

@function solid-button-color-variations($color, $level: 1) {
    @if not $level {
        @return $color;
    }

    @if $color == $kendo-color-primary {
        @if $level == $kendo-solid-button-hover-shade-bg-amount {
            @return $kendo-primary-solid-button-hover-color;
        } @else if $level == $kendo-solid-button-active-shade-bg-amount {
            @return $kendo-primary-solid-button-active-color;
        }
    } @else if $color == $kendo-color-error {
        @if $level == $kendo-solid-button-hover-shade-bg-amount {
            @return color.mix(#000000, $color, 8);
        }
    }

    @return k-try-shade($color, $level);
}

@import '~@progress/kendo-theme-default/dist/all.scss';

*,
*::before,
*::after {
    box-sizing: border-box;
}

button {
    margin: 0;
}

.k-button-solid,
.k-button-outline,
.k-button-flat {
    font-weight: $kendo-font-weight-bold;
}

.k-icon-button.k-button-md {
    padding: 7px;
}

.k-button-lg > .k-button-icon {
    font-size: 24px;
}

.k-button-sm {
    min-width: 60px;
}

.k-button-md {
    min-width: 80px;
}

.k-button-lg {
    min-width: 100px;
}

.k-button-group {
    .k-button-sm {
        min-width: 40px;
    }

    .k-button-md {
        min-width: 50px;
    }

    .k-button-lg {
        min-width: 60px;
    }
}

.k-icp-flat-button-group {
    gap: 2px;

    .k-button {
        min-width: 120px;

        &::before {
            opacity: 0.04;
        }

        &:hover::before {
            opacity: 0.08;
        }

        &.k-selected {
            color: $kendo-color-secondary;

            &::before {
                opacity: 0.08;
            }

            &:hover::before {
                opacity: 0.12;
            }
        }
    }
}

.k-icp-button-flat-alt {
    color: $kendo-base-text;

    &:before {
        background-color: var(--alt-flat-btn-bg);
    }

    &::after {
        box-shadow: inset 0 0 0 2px var(--alt-flat-btn-bg);
    }

    &:hover,
    &.k-hover {
        &::before {
            opacity: 0.04;
        }
    }

    &:active,
    &.k-active {
        &::before {
            opacity: 0.08;
        }
    }

    &.k-selected {
        &::before {
            opacity: 0.08;
        }

        &:hover,
        &.k-hover {
            &::before {
                opacity: 0.12;
            }
        }
    }
}

.k-icp-button-flat-alt {
    @each $name,
        $color
            in k-map-merge(
                $kendo-button-theme-colors,
                (
                    'base': $kendo-base-text
                )
            )
    {
        &.k-button-flat-#{$name} {
            --alt-flat-btn-bg: #{$color};
        }
    }
}

.k-icon-button,
.k-button-group .k-icon-button,
.k-button-group .k-icp-svg-icon-button,
.k-icp-flat-button-group .k-icon-button,
.k-icp-flat-button-group .k-icp-svg-icon-button,
.k-notification .k-button,
.k-input .k-button {
    min-width: 0;
}

.k-button-outline-base:not(.k-picker .k-input-button) {
    &:hover,
    &.k-hover {
        color: $kendo-body-text;
        border-color: currentColor;
        background-color: rgba($wcag-dark, 0.08);
    }

    &:active,
    &.k-active,
    &.k-selected {
        color: $wcag-light;
        background-color: $wcag-dark;
        border-color: $wcag-dark;
    }
}

.k-button-outline-secondary:not(.k-picker .k-input-button) {
    &:hover,
    &.k-hover {
        color: $kendo-color-secondary;
        border-color: currentColor;
        background-color: rgba($kendo-color-secondary, 0.08);
    }

    &:active,
    &.k-active,
    &.k-selected {
        color: $wcag-light;
        background-color: $kendo-color-secondary;
        border-color: $kendo-color-secondary;
    }
}

.k-button-flat:not(.k-picker .k-input-button) {
    &:focus,
    &.k-focus {
        &::after {
            opacity: 0.08;
        }
    }
}

.k-button-link {
    &:hover,
    &.k-hover {
        text-decoration: none;
    }

    &-secondary:hover,
    &-secondary.k-hover,
    &-secondary:active,
    &-secondary.k-active,
    &-secondary.k-selected {
        color: $kendo-link-hover-text;
    }
}

.k-button-link-alt {
    color: $link-text-alt;

    &:hover,
    &.k-hover,
    &:active,
    &.k-active,
    &.k-selected {
        color: $link-hover-text-alt;
    }
}

.k-icp-svg-icon-button {
    min-width: 0;

    &.k-button-md {
        padding: 7px;

        .k-icp-icon {
            @extend .k-icp-icon-size-4;
        }
    }

    &.k-button-sm {
        padding: 3px;

        .k-icp-icon {
            @extend .k-icp-icon-size-4;
        }
    }

    &.k-button-lg {
        padding: 7px;

        .k-icp-icon {
            @extend .k-icp-icon-size-6;
        }
    }
}

.k-icp-svg-icon-button-content {
    .k-button-lg & {
        .k-icp-icon {
            @extend .k-icp-icon-size-6;
        }
    }

    .k-button-md & {
        .k-icp-icon {
            @extend .k-icp-icon-size-4;
        }
    }

    .k-button-sm & {
        .k-icp-icon {
            @extend .k-icp-icon-size-4;
        }
    }
}

input {
    &.k-input-sm {
        font-size: $kendo-input-sm-font-size;
        line-height: $kendo-input-sm-line-height;
        padding: $kendo-input-sm-padding-y $kendo-input-sm-padding-x;
    }

    &.k-input-lg {
        font-size: $kendo-input-lg-font-size;
        line-height: $kendo-input-lg-line-height;
        padding: $kendo-input-lg-padding-y $kendo-input-lg-padding-x;
    }
}

.k-form-hint {
    color: rgba($kendo-body-text, 0.65);
}

.k-text-disabled {
    @include disabled($kendo-disabled-styling);
}

.k-text-italic {
    font-style: italic;
}

.k-label,
.k-icp-line-height-inline-md {
    line-height: 22px;
}

.\!k-icp-line-height-inline-md {
    line-height: 22px !important;
}

.k-icp-text-sm {
    font-size: $kendo-font-size-sm;
    line-height: $kendo-line-height;
}

.\!k-icp-text-sm {
    font-size: $kendo-font-size-sm !important;
    line-height: $kendo-line-height !important;
}

.k-icp-text-lg {
    font-size: $kendo-font-size-lg;
    line-height: $kendo-line-height;
}

.k-icp-text-xs {
    font-size: 10px;
    line-height: 15px;
}

.k-icp-text-smaller {
    font-size: 11px;
    line-height: 16px;
}

.k-icp-text-input-like {
    line-height: 22px;
    padding-block: 5px;
}

.k-icp-subtle-text {
    color: $kendo-subtle-text;
}

.k-icp-placeholder-text {
    color: $kendo-input-placeholder-text;
}

.k-icp-white-text {
    color: $wcag-light;
}

.k-icp-white-ghost-text {
    color: $white-ghost;
}

.k-icp-ghost {
    opacity: 0.5;
}

.k-icp-font-weight-medium {
    font-weight: 500;
}

.k-avatar-solid-base {
    background-color: $kendo-component-bg;
    border-color: $kendo-component-border;
}

.k-avatar-text {
    font-weight: $kendo-font-weight-bold;
}

.k-avatar-sm .k-avatar-text {
    font-size: 7px;
}

.k-avatar-xl .k-avatar-text {
    font-size: $kendo-display4-font-size;
}

$avatar-simple-bg-colors: $kendo-base-bg, #bab9d7, #bed3d9, #bfd9c7, #c6d4e3, #cfbad8, #cfdabc, #cfe3f2, #d4ead0, #d5bacf, #d7cbbb, #d9d5bc, #dbd2e9, #f2e5b7,
    #f5c3bf;

@each $bg-color in $avatar-simple-bg-colors {
    $i: index($avatar-simple-bg-colors, $bg-color) - 1;
    .k-icp-avatar-simple-bg-#{$i} {
        background-color: $bg-color;
    }
}

.k-icp-avatar-gradient-bg {
    background-image: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(235, 235, 235, 1) 50%, rgba(235, 235, 235, 0.84) 100%);
}

.k-icp-avatar-separated {
    --avatar-separator-width: 2px;

    border-width: 0;
    box-shadow: 0 0 0 var(--avatar-separator-width) $wcag-light;
}

.k-icp-avatar-compact-list {
    --avatar-overlap: 8px;

    padding-inline-start: var(--avatar-overlap);

    > * {
        margin-inline-start: calc(var(--avatar-overlap) * -1);
    }

    .k-avatar {
        @extend .k-icp-avatar-separated;
    }
}

.k-icp-avatar-compact-list-sm {
    --avatar-overlap: 4px;

    .k-avatar {
        --avatar-separator-width: 1px;
    }
}

.k-icp-avatar-light-text.k-avatar-md {
    .k-avatar-text {
        font-size: 14px;
        font-weight: 400;
    }
}

.k-icp-avatar-default-text {
    .k-avatar-text {
        font-size: $kendo-avatar-font-size;
    }
}

.k-icp-avatar-transparent-border {
    border-color: transparent;
}

.k-icp-avatar-dimmed {
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $wcag-light;
        opacity: 0.5;
    }
}

%avatar-hover {
    box-shadow: $shadow-base;
}

%avatar-selected {
    box-shadow: 0px 0px 0px $shadow-spread rgba(0, 0, 0, 0.12);
}

.k-icp-avatar-hoverable {
    &:hover,
    &.k-hover {
        @extend %avatar-hover;
    }
}

.k-icp-avatar-selected {
    &,
    &:hover,
    &.k-hover {
        @extend %avatar-selected;
    }
}

.k-icp-avatar-interactive-group {
    display: inline-flex;
    gap: 4px;
    align-items: center;

    .k-icon {
        transition: transform 200ms ease-out;
    }

    &:hover {
        .k-avatar {
            @extend %avatar-hover;
        }

        .k-icon {
            transform: translateY(4px);
        }

        .k-icp-avatar-interactive-group-link {
            color: $kendo-color-secondary;
        }
    }

    &.k-selected {
        .k-avatar {
            @extend %avatar-selected;
        }
    }
}

.k-icp-hidden-file-input {
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.k-icp-badged-image-input-controls {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white-ghost;
    padding: 2px 0 6px 0;
}

.k-icp-badged-image-input {
    position: relative;
}

.k-icp-badged-image-input-full {
    &:hover,
    &.k-hover {
        border-color: $kendo-component-border;

        .k-icp-badged-image-input-controls {
            display: flex;
        }
    }
}

.k-icp-badged-image-input-empty-trigger {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: $kendo-button-hover-bg;
}

.k-icp-badged-image-input-empty {
    &:hover,
    &.k-hover {
        border-width: $kendo-avatar-border-width;
        border-color: $kendo-component-border;

        .k-icp-badged-image-input-empty-trigger {
            display: flex;
        }
    }
}

.k-icp-badged-image-drag-notice {
    display: none;
    background: $kendo-button-hover-bg;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
}

.k-icp-badged-image-drop-area {
    position: relative;
}

.k-icp-badged-image-drop-area-dragged-over {
    border: $kendo-button-border-width dashed $kendo-button-border;
    background: $kendo-button-hover-bg;

    .k-icp-badged-image-drag-notice {
        display: flex;
    }
}

svg.k-icp-icon {
    display: block;
    stroke: currentColor;

    path {
        stroke: currentColor;
        vector-effect: non-scaling-stroke;
    }
}

.k-icp-fill-primary {
    fill: $kendo-color-primary;
}

.k-icp-fill-base {
    fill: $wcag-dark;
}

.k-icp-fill-secondary {
    fill: $kendo-color-secondary;
}

.k-icp-icon-size-4 {
    width: 16px;
    height: 16px;
}

.k-icp-icon-size-3 {
    width: 12px;
    height: 12px;
}

.k-icp-icon-size-6 {
    width: 24px;
    height: 24px;
}

.k-icp-icon-size-8 {
    width: 32px;
    height: 32px;
}

.k-icp-icon-size-10 {
    width: 40px;
    height: 40px;
}

.k-icp-icon-background {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-warning {
        background-color: rgba($kendo-color-warning, 0.16);
    }

    &-success {
        background-color: rgba($kendo-color-success, 0.08);
    }
}

.k-icp-tooltip-trigger {
    > * {
        pointer-events: none;
    }
}

$file-upload-min-height: 160px;

.k-icp-file-upload {
    min-height: $file-upload-min-height;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.k-icp-file-upload-dragged-over {
    background: $kendo-button-hover-bg;
    border: $kendo-button-border-width dashed $kendo-button-border;

    * {
        pointer-events: none !important;
    }
}

.k-icp-file-upload-uploaded-item-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.k-icp-file-upload-uploaded-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    img {
        display: block;
        max-width: 100%;
        max-height: $file-upload-min-height - $kendo-button-border-width * 2;
    }
}

.k-icp-file-upload-remove-item-btn {
    position: absolute;
    top: 1px;
    right: 1px;

    background-color: $white-ghost !important;
    border-radius: $kendo-border-radius;
}

.k-popover-actions {
    padding-top: 8px;
    padding-bottom: 8px;
}

.k-icp-tooltip-popover {
    background-color: $kendo-tooltip-bg;
    color: $kendo-tooltip-text;
    border-color: $kendo-tooltip-border;
    box-shadow: $elevated-shadow-sm;
    font-size: $kendo-tooltip-font-size;
    border-radius: $kendo-tooltip-border-radius;

    .k-popover-body {
        padding: $kendo-tooltip-padding-y $kendo-tooltip-padding-x;
    }

    .k-popover-callout {
        background-color: $kendo-tooltip-bg;
        color: $kendo-tooltip-text;
        border-color: $kendo-tooltip-border;
        width: $kendo-tooltip-callout-size * math.sqrt(2) + 1;
        height: $kendo-tooltip-callout-size * math.sqrt(2) + 1;
    }
}

.k-icp-tooltip-popover-light {
    @extend .k-icp-tooltip-popover;
    background-color: #f5f8fb;
    color: $wcag-dark;
    box-shadow: $elevated-shadow-md;
    border-color: #f5f8fb;

    .k-popover-body {
        padding-inline: 0px;
        padding-block: 0px;
    }

    .k-popover-callout {
        background-color: #f5f8fb;
        color: $wcag-dark;
        border-color: #f5f8fb;
    }
}

.k-icp-tooltip-popover-closable {
    .k-popover-body {
        padding: 0;
    }

    .k-icp-tooltip-popover-content {
        padding: $kendo-tooltip-padding-y $kendo-tooltip-padding-x;
    }

    .k-separator {
        border-color: rgba($wcag-light, 0.2);
    }
}

.k-icp-popover-error {
    max-width: 160px;

    &,
    & .k-popover-callout {
        background-color: $kendo-color-error;
        border-color: $kendo-color-error;
    }
}

.k-icp-popover-with-bg {
    .k-popover-body,
    .k-popover-callout {
        background-color: var(--popover-bg);
        border-color: var(--popover-bg);
    }
}

.k-icp-popover-link-alt {
    --popover-bg: #{$kendo-color-secondary-pale};
}

.k-icp-popover-secondary-8 {
    --popover-bg: #{color.mix($kendo-color-secondary, $wcag-light, 8)};
}

.k-icp-secondary-pale-bg {
    background-color: $kendo-color-secondary-pale;
}

.k-icp-no-padding-popover {
    .k-popover-body {
        padding: 0;
    }
}

.k-window {
    max-height: 80vh;
    max-width: 100vw;
}

.k-icp-dialog-max-h-half {
    .k-window {
        max-height: 50vh;
    }
}

.k-icp-dialog-fixed-height {
    .k-window {
        height: 600px;
    }
}

.k-icp-dialog-maximized > .k-dialog {
    max-height: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
}

.k-icp-dialog-responsive > .k-dialog {
    max-width: 100%;
}

.k-window-title {
    font-weight: $kendo-h2-font-weight;
}

.k-dialog-content {
    min-height: 124px;
}

.k-icp-dialog-no-title {
    .k-dialog-titlebar {
        border-bottom: none;
    }

    .k-dialog-content {
        padding-top: 0;
    }
}

.k-icp-dialog-no-padding {
    > .k-window > .k-window-content {
        padding: 0;
    }
}

// Fix scrolling of content since when we have a form the dialog actions buttons are within the form and they do not stick while scrolling
.k-icp-dialog-with-form {
    > .k-window > .k-window-content {
        display: flex;
        flex-direction: column;
    }

    form {
        flex: 1 1 auto;
        min-height: 0;
        display: flex;
        flex-direction: column;
    }
}

.k-icp-dialog-with-pretitle-actions {
    .k-window-titlebar {
        padding-left: 0;
        padding-block: 8px;
    }

    .k-window-title {
        padding-block: 8px;
        margin-block: -8px;
    }

    .k-icp-pretitle-actions {
        margin-block: -8px;
        margin-right: 16px;

        display: flex;
    }

    .k-icp-pretitle-button {
        padding: 11px;
    }
}

.k-icp-dialog-with-title-shadow {
    .k-window-titlebar {
        box-shadow: $bar-shadow;
        z-index: 1;
    }
}

.k-icp-pretitle-action-popover {
    .k-popover {
        width: 400px;
    }

    .k-popover-body {
        padding-top: 12px;
    }
}

.k-list-item {
    &.k-selected:hover,
    &.k-selected.k-hover {
        background-color: $kendo-selected-hover-bg;
    }
}

.k-list-item.k-custom-item {
    font-style: normal !important;
    font-weight: $kendo-font-weight-bold;
    color: $kendo-color-secondary;

    > .k-icon {
        color: $kendo-body-text;
    }
}

.k-icp-panel {
    border: 1px solid $kendo-component-border;
    border-radius: $kendo-card-border-radius;
    background-color: $kendo-component-bg;

    &.k-invalid {
        border-color: $kendo-color-error;
    }

    &.k-warning {
        border-color: $kendo-color-warning;
    }
}

.k-icp-panel-section {
    padding: 8px 16px;
    display: flex;
}

a.k-icp-panel-section:hover {
    background-color: $kendo-grid-hover-bg;
}

.k-icp-panel-separated-section {
    border-bottom: 1px solid;
    border-bottom-color: inherit;
    padding-bottom: 7px;
    border-color: $kendo-component-border;

    &:last-child {
        border-bottom: none;
    }
}

.k-icp-panel-header-section {
    min-height: 48px;
    align-items: center;
    justify-content: space-between;
}

.k-icp-panel-footer-section {
    border-top: 1px solid;
    border-top-color: inherit;
    padding-top: 7px;
}

.k-icp-panel-section-header {
    flex: 0 0 160px;
}

.k-icp-panel-content {
    padding: 16px 24px;
}

.k-icp-panel-image {
    display: flex;

    .k-icp-panel-content {
        flex: 1;
        padding-right: 0;
    }
}

.k-icp-panel-secondary {
    background-color: rgba($kendo-color-secondary, 0.08);
    border-color: rgba($kendo-color-secondary, 0.12);
}

.k-icp-panel-sand {
    background-color: #fbfbf9;
}

.k-icp-panel-success {
    background-color: rgba($kendo-color-success, 0.16);
    border-color: transparent;
}

.k-icp-panel-base {
    background-color: $kendo-base-bg;
}

.k-icp-panel-outline {
    background-color: transparent;

    &.k-icp-panel-base {
        border-color: $kendo-picker-hover-border;
    }

    &.k-icp-panel-secondary {
        border-color: rgba($kendo-color-secondary, 0.24);
    }
}

.k-checkbox-label {
    .k-checkbox-md + & {
        font-size: $kendo-font-size;
        line-height: 22px;
    }

    .k-checkbox-sm + & {
        font-size: $kendo-font-size-sm;
        margin-inline-start: 4px;
    }

    .k-checkbox-lg + & {
        font-size: $kendo-font-size-lg;
    }
}

.k-icp-bg-component {
    background-color: $kendo-component-bg;
}

.k-icp-bg-component-40 {
    background-color: rgba($kendo-component-bg, 0.4);
}

.k-icp-bg-success-8-solid {
    background-color: $success-8-solid;
}

.k-icp-bg-success-8 {
    background-color: rgba($kendo-color-success, 0.08);
}

.k-icp-bg-success-24 {
    background-color: rgba($kendo-color-success, 0.24);
}

.k-icp-bg-error-4 {
    background-color: rgba($kendo-color-error, 0.04);
}

.k-icp-bg-error-8 {
    background-color: rgba($kendo-color-error, 0.08);
}

.k-icp-bg-error-12 {
    background-color: rgba($kendo-color-error, 0.12);
}

.k-icp-bg-warning-8-solid {
    background-color: $warning-8-solid;
}

.k-icp-bg-warning-8 {
    background-color: rgba($kendo-color-warning, 0.08);
}

.k-icp-bg-warning-16 {
    background-color: rgba($kendo-color-warning, 0.16);
}

.k-icp-bg-warning-24 {
    background-color: rgba($kendo-color-warning, 0.24);
}

.k-icp-bg-info-8 {
    background-color: rgba($kendo-color-info, 0.08);
}

.k-icp-bg-secondary-4 {
    background-color: rgba($kendo-color-secondary, 0.04);
}

.k-icp-bg-secondary-8 {
    background-color: rgba($kendo-color-secondary, 0.08);
}

.k-icp-bg-secondary-12 {
    background-color: color.mix($kendo-color-secondary, $wcag-light, 12);
}

.k-icp-bg-secondary-24 {
    background-color: rgba($kendo-color-secondary, 0.24);
}

.k-icp-bg-dark-6 {
    background-color: rgba($wcag-dark, 0.06);
}

.k-icp-bg-dark-8 {
    background-color: rgba($wcag-dark, 0.08);
}

.k-icp-bg-primary-16 {
    background-color: rgba($kendo-color-primary, 0.16);
}

.k-icp-bg-primary-30 {
    background-color: rgba($kendo-color-primary, 0.3);
}

.k-icp-bg-light-16 {
    background-color: rgba($wcag-light, 0.16);
}

.k-icp-bg-hover-bg {
    background-color: $kendo-hover-bg;
}

.k-notification-closable {
    align-items: flex-start;
    gap: 12px;

    .k-notification-actions {
        align-self: flex-start;
        margin-top: 4px;
    }
}

.k-icp-notification-content {
    min-width: 240px;
    max-width: 340px;
    overflow-wrap: anywhere;
}

.k-icp-notification-btn-error {
    color: $kendo-color-dark;

    &:hover {
        color: $kendo-link-hover-text;
    }
}

h4,
.k-h4 {
    text-transform: uppercase;
}

.k-radio-list-condensed {
    .k-radio-item {
        padding-block: 0;
    }
}

.k-radio-item {
    gap: $kendo-radio-label-margin-x;
}

.k-radio.k-icp-radio-margin-lg + .k-radio-label {
    margin-inline-start: 16px;
}

// fix for: https://github.com/telerik/kendo-themes/issues/3983
.k-fab {
    outline: none;
}

.k-stepper {
    .k-step-link {
        cursor: pointer;
    }

    .k-step-indicator::after {
        border-color: $kendo-color-secondary;
    }

    .k-step-focus,
    .k-step.k-focus,
    .k-step-link:focus {
        .k-step-indicator::after {
            display: none;
        }
    }

    .k-step-current .k-step-indicator::after {
        display: block !important;
    }
}

.k-icp-stepper-with-progress-hint {
    .k-progressbar {
        overflow: hidden;

        .k-selected {
            position: relative;
            overflow: visible;

            &::after {
                display: block;
                content: '';
                width: 100%;
                height: $kendo-stepper-indicator-height - 2 * $kendo-stepper-indicator-border-width;
                position: absolute;
                background-color: inherit;
                bottom: 0;
                transform: translateY(100%);
            }
        }
    }
}

.k-icp-expand-collapsible-toggle {
    width: 100%;
    border-top-color: rgba(0, 83, 166, 0.12) !important;
}

.k-icp-expand-collapsible-content {
    overflow-y: hidden;
}

.k-icp-expand-collapsible-collapsed {
    .k-icp-expand-collapsible-content {
        max-height: 90px;
    }

    .k-icp-expand-collapsible-toggle .k-icp-icon {
        transform: rotateZ(180deg);
    }
}

.k-icp-paged-content-page-indicator {
    width: 16px;
    height: 16px;

    &::before {
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        border: 1px solid currentColor;
        border-radius: 4px;

        transition: width 300ms ease-out, background-color 300ms ease-out;
    }

    &-current {
        &::before {
            width: 18px;
            background-color: currentColor;
        }
    }
}

.k-icp-dashed-border {
    border: transparent;
    //Note that SVG stroke is 2px, because it is clipped due to default svg browser behavior of hidden overflow and is effectively 1px. The parent element must have border radius of 8px, otherwise the corners will be bolder.
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='#{rgba($kendo-component-border, 0.16)}' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
}

.k-icp-component-border {
    border-color: $kendo-component-border;
}

.\!k-icp-component-border {
    border-color: $kendo-component-border !important;
}

.k-icp-component-border-4 {
    border-color: rgba($kendo-component-border, 0.04);
}

.k-icp-component-border-16 {
    border-color: rgba($kendo-component-border, 0.16);
}

.k-icp-outline-border {
    border-color: rgba($kendo-body-text, 0.12);
}

.k-icp-border-base-50 {
    border-color: $kendo-picker-outline-hover-border;
}

.k-icp-border-success-30 {
    border-color: rgba($kendo-color-success, 0.3);
}

.k-icp-border-error-30 {
    border-color: rgba($kendo-color-error, 0.3);
}

.k-icp-border-secondary-24 {
    border-color: rgba($kendo-color-secondary, 0.24);
}

.k-icp-bordered-top {
    border-top-width: 1px;
    border-top-style: solid;
}

.k-icp-tabbed-nav-header {
    border-bottom: 3px solid transparent;

    &:hover {
        border-color: $kendo-picker-hover-border;
    }
}

.k-icp-interactive-card {
    transition: box-shadow 200ms ease-in;

    &:hover {
        box-shadow: $elevated-shadow-md;
    }

    &-sm {
        transition: box-shadow 100ms ease-in;

        &:hover {
            box-shadow: $elevated-shadow-sm;
            border-color: $kendo-picker-hover-border;
        }
    }

    &-xs {
        transition: box-shadow 100ms ease-in;

        &:hover {
            box-shadow: $elevated-shadow-xs;
        }
    }
}

.k-column-title {
    text-transform: uppercase;
    font-size: $kendo-font-size-sm;
}

.k-icp-grid-navigatable tr {
    cursor: pointer;
}

.k-icp-grid-no-header {
    .k-grid-header {
        display: none;
    }

    .k-grid-toolbar {
        border-bottom-width: $kendo-grid-cell-horizontal-border-width;
        background-color: transparent;
    }
}

.k-icp-grid-light-header {
    .k-table-thead,
    .k-table-header,
    .k-table-group-sticky-header {
        background-color: $wcag-light;
    }
}

.k-icp-grid-sticky-header {
    .k-grid-header {
        position: sticky;
        top: 0;
        z-index: 99999;
    }

    .k-grid-container {
        overflow: visible;
    }
}

.k-grid .k-icp-actions-column {
    padding-top: $kendo-grid-edit-cell-padding-y;
    padding-bottom: $kendo-grid-edit-cell-padding-y;
}

.k-icp-horizontal-input-label {
    @extend .k-icp-panel-section-header;

    > .k-label {
        margin-bottom: 0;
        margin-top: 5px;
    }

    .k-icp-horizontal-input-narrow & {
        flex-basis: 120px;
    }

    .k-icp-horizontal-input-compact & {
        flex-basis: auto;
    }
}

.k-icp-no-focus-shadow {
    &:focus,
    &:focus-within,
    &.k-focus {
        box-shadow: none;
    }
}

.k-icp-no-shadow {
    box-shadow: none;
}

.k-icp-chip-solid-base-not-interactive {
    @extend .k-icp-no-focus-shadow;
    cursor: default;

    &:hover,
    &.k-hover {
        background-color: $kendo-chip-solid-bg;
    }
}

.k-color-base,
.k-text-base {
    color: $kendo-body-text;
}

.k-pager-numbers-wrap {
    .k-button {
        border-radius: 4px;
    }

    .k-button-flat-primary {
        @extend .k-button-flat-base;
    }
}

.k-calendar {
    .k-calendar-view .k-today {
        font-weight: $kendo-font-weight-bold;
    }

    .k-icp-calendar-available-day {
        .k-link {
            background-color: rgba($kendo-color-secondary, 0.08);
        }

        &:hover {
            .k-link {
                background-color: rgba($kendo-color-secondary, 0.12);
            }
        }

        &.k-selected {
            .k-link {
                background-color: $kendo-color-secondary;
                color: $wcag-light;
            }

            &:hover {
                .k-link {
                    background-color: call($kendo-solid-button-shade-function, $kendo-color-secondary, $kendo-solid-button-hover-shade-bg-amount);
                    color: $wcag-light;
                }
            }
        }
    }
}

.k-calendar-header .k-calendar-nav {
    color: $kendo-color-secondary;
}

.k-calendar-caption {
    text-align: center;
    text-transform: uppercase;
}

.k-icp-calendar-today-disabled {
    .k-calendar-nav-today {
        @include disabled($kendo-disabled-styling);
    }
}

.k-time-list {
    .k-item:hover {
        color: $kendo-link-hover-text;
    }
}

.k-scheduler-cell.k-heading-cell-today {
    color: $kendo-color-secondary;
    background-color: rgba($kendo-color-secondary, 0.04);
}

.k-scheduler-cell.k-slot-cell-today {
    background-color: rgba($kendo-color-secondary, 0.04);

    .k-nav-day {
        color: $kendo-color-secondary;
        font-weight: $kendo-font-weight-bold;
    }
}

.k-icp-scheduler-adaptive-height {
    flex: 1 1 auto;

    .k-scheduler-layout-flex {
        display: flex;
        flex-direction: column;

        .k-scheduler-head {
            flex-basis: auto;
        }

        .k-scheduler-group .k-group-cell {
            overflow-x: hidden;
        }
    }
}

.k-event {
    font-size: 12px;
    border-width: 1px;
    padding-right: 0;

    > div {
        position: absolute;
        inset: 0;
    }

    .k-event-template {
        padding-block: 3px;
        padding-inline: 3px;
    }
}

.k-icp-plain-text-editor-content {
    overflow-wrap: break-word;
    white-space: pre-wrap;
    outline: none;
}

.k-icp-text-sentencecase {
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }
}

.k-icp-daterangepicker-with-static-popup {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    justify-content: start;
    row-gap: 4px;

    .k-dateinput {
        width: 235px;
    }

    > .k-calendar-range,
    > .k-icp-calendar-range-with-footer {
        grid-row: 2;
        grid-column: 1/-1;
    }
}

.k-icp-shadow-xs {
    box-shadow: $elevated-shadow-xs;
}

.k-icp-shadow-sm {
    box-shadow: $elevated-shadow-sm;
}

.\!k-icp-shadow-sm {
    box-shadow: $elevated-shadow-sm !important;
}

.k-icp-shadow-md {
    box-shadow: $elevated-shadow-md;
}

.k-icp-shadow-md-moved {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
}

.k-menu-popup-with-header {
    > .k-menu-group > .k-menu-item:first-child > .k-menu-link {
        pointer-events: none;
        white-space: normal;
        background-color: $kendo-base-bg;
        color: $kendo-subtle-text;
    }
}

.k-icp-hover-text-base:hover {
    color: $kendo-body-text;
}

.k-icp-hover-bg-base-4:hover {
    background-color: rgba($kendo-body-text, 0.04);
}

.k-icp-hover-bg-base-8:hover {
    background-color: rgba($kendo-body-text, 0.08);
}

.k-icp-hover-bg-secondary-8:hover {
    background-color: rgba($kendo-color-secondary, 0.08);
}

.k-icp-on-off-switch.k-switch-off .k-switch-thumb {
    background-color: rgba($wcag-dark, 0.5);
}

.k-rounded-xl {
    border-radius: 16px;
}

.k-icp-hover-subtle-text-effect {
    color: $kendo-body-text;
    transition: color 100ms linear;

    &:hover {
        color: $kendo-subtle-text;
    }
}

.k-editor-content .selectedCell::after {
    background-color: transparent;
}

.k-editor-content .selectedCell {
    background-color: rgba($link-text-alt, 0.16);
}
